import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, Output, EventEmitter, TemplateRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { DataService } from '../../../services/data.service';
import { CommonJs } from '../../../common/common-js';
declare var $: any;
import * as _ from "lodash";
import * as moment from 'moment';
import { ToastrService } from '../../../services/toastr.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'email-subscription',
  templateUrl: './email-subscription.component.html',
  styleUrls: ['./email-subscription.component.css']
})
export class EmailSubscriptionComponent implements OnInit {
  entityName = 'Email Subscriptions';
  entityNameSingular = 'Email Subscription';
  apiRoutePrefix = 'email-subscription';

  /*	@ViewChild('CloseOtherWindow') CloseOtherWindow:ElementRef;*/
  @ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
  @ViewChild('multiSelect', { static: false }) multiSelect: ElementRef;

  @Output() closeSettings = new EventEmitter();

  data: any;
  myform: any;
  errors: { [index: string]: any } = {};
  edit_errors: { [index: string]: any } = {};
  subscription: { [index: string]: any } = {};
  isSubmitted: boolean = false;
  emailSubscriptions = [];

  projectId: number;

  emailType: string = 'weekly';
  isEmailSubscription: boolean = true;
  deptWiseEmailSub: boolean = true;

  department = [];
  departments = [];
  subscribedDepartments = [];
  focused: string = '';
  isDepartmentSubmitted: boolean = false;
  bsModalRef: BsModalRef;
  modalData: any;
  cellUpdateType: string = '';

  dropdownSettings = {
    singleSelection: true,
    idField: 'column',
    textField: 'headerDisplay',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };
  selectedDepartments = [];
  deptCellForUpdate = 0;
  allDepts = [];

  constructor(
    private dataService: DataService,
    public commonJs: CommonJs,
    private titleService: Title,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {

    this.getDepartments('');

    this.activatedRoute.params.subscribe((params: Params) => {
      //localStorage.setItem('projectId',params['id']);
      this.projectId = params['id'];
    });
    if (this.projectId) {
      this.emailType = 'daily';
    }
    this.getEmailSubscriptions();
    this.titleService.setTitle(this.entityName);
  }

  getEmailSubscriptions() {
    this.dataService.getOtherData(this.apiRoutePrefix + 's', { project_id: this.projectId })
      .subscribe((success) => {
        let tempEmailSubs = success.data;

        this.emailSubscriptions = tempEmailSubs.map((ele)=>{
          ele.departmentsList ? '': ele.departmentsList=[];

          if(ele.departments){
            let departmentsCopy = JSON.parse(ele.departments);

            if(departmentsCopy && departmentsCopy.length){
                departmentsCopy.forEach((element) => {
                  if(typeof(element)=='string'){
                    element = JSON.parse(element);
                  }

                  if(this.allDepts.find(dept => dept.department_id == element)){
                    ele.departmentsList.push(this.allDepts.find(dept => dept.department_id == element));
                  }

                });
            }
          }

          return ele;
        });
      }, (rejected) => {
        // this.emailSubscriptions = [];

      });
  }

  onSubmitEmail(form: NgForm) {
    this.isSubmitted = true;
    this.errors = {};

    form.value.type = 'weekly_studio_dashboard';
    if (this.projectId) {
      form.value.project_id = this.projectId;
      form.value.type = 'daily_studio_dashboard';
    }

    this.dataService.storeData(this.apiRoutePrefix, form.value)
      .subscribe((success) => {

        setTimeout(() => {
          this.subscription.email = '';
          this.getEmailSubscriptions();
        }, 2);

        setTimeout(() => {
          this.isSubmitted = false;
        }, 1500);

        this.toastrService.notify(success.status, '', success.message);

      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
        }
        this.isSubmitted = false;
      });
  }

  cellClick(id, type = 'email-subscription'): any {
    if (id != undefined) {
      if(type=='departmentsCell'){
        this.deptCellForUpdate = id;
        $('.cursor-pointer').removeClass('active');
        $('.' + id + '-dept-td').addClass('active');
        // $('.' + id + '-autoComplete-input p-autocomplete').focus();

      }else{
        $('.cursor-pointer').removeClass('active');
        $('.' + id + '-td').addClass('active');
        $('.' + id + '-input input').focus();
      }
    }
  }

  cellUpdate(data: any = {}, type = 'email-subscription', isDelete = false, isDone = false, checkboxVal = ''): any {
    if (isDelete && type == 'email-subscription') {
      this.dataService.deleteData('emailSubscriptionDestroy', [data.id])
        .subscribe((success) => {
          $('.' + data.id + '-td').removeClass('active');
          this.toastrService.notify(success.status, '', success.message);
          this.getEmailSubscriptions();
        }, (rejected) => {

        });

    } else if (!isDelete && type == 'email-subscription') {
      this.dataService.updateData(this.apiRoutePrefix + '-deptwise/' + data.id, data)
        .subscribe((success) => {

          $('.' + data.id + '-td').removeClass('active');
          // this.getEmailSubscriptions();

        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.edit_errors = err.error;
            //this.toastrService.notify(success.status, '', success.message);
            this.toastrService.notify('warn', '', this.edit_errors.email);
          }
        });
    } else if (isDelete && type == 'department') {
      this.dataService.deleteData('removeDepartmentFromEmail', [data.id])
        .subscribe((success) => {

          $('.' + data.id + '-td').removeClass('active');
          this.getSubscribedDepartments();

        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.edit_errors[data.id] = err.error;
          }
        });
    }
    if(this.bsModalRef){
      this.bsModalRef.hide();
    }
    this.deptCellForUpdate = 0;
  }

  search(query, column) {
    if (column == 'departments') {
      this.getDepartments(query);
    }
  }

  getDepartments(query) {
    this.dataService.getListData('getProjectDepartments', { projectId: this.projectId, 'query': query['query'] })
      .subscribe((success) => {
        if(query==''){
          this.allDepts = success.data;
        }
        this.departments = success.data;
      }, (rejected) => {

      });
  }

  onAddDepartments(form: NgForm) {
    let department_id = form.value.department.department_id;
    let department = form.value.department;
    this.errors = {};
    this.dataService.getListData('addDepartmentForEmail', { projectId: this.projectId, 'department': department, 'department_id': department_id })
      .subscribe((success) => {
        this.departments = success.data;
        this.department = [];
        this.focused = '';
        this.getSubscribedDepartments();

        this.toastrService.notify(success.status, '', success.message);
      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
        }
        this.isDepartmentSubmitted = false;
      });
  }

  getSubscribedDepartments() {
    this.dataService.getOtherData('getSubscribedDepartments', { project_id: this.projectId })
      .subscribe((success) => {
        this.subscribedDepartments = success.data;
      }, (rejected) => {

      });
  }

  toggleView(type) {
    // this.isEmailSubscription = !this.isEmailSubscription;
    if(type=='deptWiseEmailSub'){
      this.deptWiseEmailSub = true;
      this.entityName = 'Email Subscriptions (Dept wise)';
    }else{
      this.deptWiseEmailSub = false;

      this.isEmailSubscription = type=='email' ? true:false;
      if (this.isEmailSubscription) {
        this.entityName = 'Email Subscriptions';
        this.getEmailSubscriptions();
      } else {
        this.getSubscribedDepartments();
        this.entityName = 'Add Departments';
      }
    }
  }

  // To open modal
  openModal(template: TemplateRef<any>, cellUpdateType, data) {
    this.bsModalRef = this.modalService.show(template);
    // console.log(data);
    this.modalData = data;
    this.cellUpdateType = cellUpdateType;
  }

  // on Email wise Department selected
  onEmailwiseDeptSelect(event, cellUpdate?, data?:any){
    // console.log(event, this.selectedDepartments);
    // console.log(JSON.parse(data['departments']).length, event['department_id']);
    if(cellUpdate && data['departmentsList'] && data['departments']){
      // console.log(event,data['departmentsList']);
      // console.log(data['departmentsList'].find((dt:any)=> dt.department_id == event.department_id));
      let tempDeptElement = data['departmentsList'].find((dt)=>dt.department_id==event.department_id);
      // console.log(!tempDeptElement);
      if(!tempDeptElement){
        data['departmentsList'].push(event);
      }
      // console.log(data['departments'],typeof(data['departments']))
      let tempDepts =  typeof(data['departments'])=='string' ?JSON.parse(data['departments']):data['departments'];
      // console.log(typeof(tempDepts))
      tempDepts.push(event['department_id']);
      data['departments'] = tempDepts;
      // console.log(JSON.parse(data['departments']), data['departmentsList']);
      this.cellUpdate(data, 'email-subscription', false);
    }


  }

  // on Email wise Department removed
  onEmailwiseDeptRemoved(event, cellUpdate?, data?:any){
      // console.log(event,data['departmentsList'].length);
    if(cellUpdate && data['departmentsList']){
      if(data['departmentsList'].length<=0){
        data['departmentsList'].push(event);
        this.toastrService.notify('warn', '','Atleast one department required!');
      }
      else{
        data['departments'] = [];
        data['departmentsList'].forEach( (element) => {
            data['departments'].push(element['department_id']);
        });

        this.cellUpdate(data, 'email-subscription', false);
      }
    }
  }

  onSubmitDeptWiseEmail(form: NgForm) {
    this.isSubmitted = true;
    this.errors = {};

    form.value.type = 'weekly_studio_dashboard';
    if (this.projectId) {
      form.value.project_id = this.projectId;
      form.value.type = 'daily_studio_dashboard';
    }

    // console.log(form.value);return;
    this.dataService.storeData(this.apiRoutePrefix+'-deptwise', form.value)
      .subscribe((success) => {

        this.selectedDepartments = [];

        setTimeout(() => {
          this.subscription.email = '';
          this.getEmailSubscriptions();
        }, 2);

        setTimeout(() => {
          this.isSubmitted = false;
        }, 1500);

        this.toastrService.notify(success.status, '', success.message);

      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
        }
        this.isSubmitted = false;
      });
  }

}
